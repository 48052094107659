var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('h1',[_vm._v("Gemeldete Turner für "+_vm._s(_vm.teamname)+":")]),_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{attrs:{"items":_vm.teamathletes,"headers":[
        { text: 'Nachname', value: 'familyName' },
        { text: 'Vorname', value: 'givenName' },
        { text: 'DTB-ID', value: 'dtbid', sortable: false },
        { text: 'Startmarke für', value: 'turnportal.club' },
        { text: 'Gültig ab', value: 'turnportal.validFrom' },
        { text: 'Gültig bis', value: 'turnportal.validUntil' },
        { text: 'Gesperrt', value: 'turnportal.banned' },
        { text: 'Letzte Überprüfung', value: 'turnportal.lastCheck' },
        { text: ("Geräte in DTL " + _vm.vorjahr + "*"), value: 'dtlgeraete', align: 'center', sortable: false }
      ],"sort-by":"familyName","items-per-page":-1},scopedSlots:_vm._u([{key:"item.turnportal.validFrom",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.turnportal.validFrom))+" ")]}},{key:"item.turnportal.validUntil",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.turnportal.validUntil))+" ")]}},{key:"item.turnportal.banned",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.turnportal.banned ? 'JA!' : 'nein')+" ")]}},{key:"item.turnportal.lastCheck",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateformat")(item.turnportal.lastCheck))+" ")]}},{key:"item.dtlgeraete",fn:function(ref){
      var item = ref.item;
return [_c('div',{style:({ background: item.dtlgeraete === null ? '#fc9895' : (item.dtlauthorized ? '#a2fca5' : '#fad26b') , 'border-radius':'4px' })},[_c('base-edit-dialog',{attrs:{"value":item.dtlgeraete === null ? '' : item.dtlgeraete,"id":item._id,"label":"Geräte in DTL"},on:{"input":function (ref) {
            var value = ref.value;
            var id = ref.id;

            return _vm.savedtl(id, value);
      }}})],1)]}}])})],1),_c('p',[_c('i',[_vm._v("* bitte geben Sie an, wie viele Geräteeinsätze die Turner in der DTL in der Vorsaison hatten.")]),_vm._v(" Hatte ein Turner einen Einsatz in der DTL, so muss dieser zuerst vom Ligabeauftragten freigegeben werden, das Feld ist dann orange.")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }